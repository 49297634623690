import { get, post, postParams, postUrl, getUrl } from './http';

export function getSelectByUserId() {
	return get(`/insurance/api/cpsIndex/selectByUserId`);
}
// 获取一级分类
export function getProductTypes() {
	return post(`/insurance/api/product/productTypes`);
}
// 获取二级分类
export function getProductTwoTypes(leve1Name) {
	return postParams(`/insurance/api/product/productSubType`, { leve1Name });
}
//获取所有产品标签
export function getProductLabelpost() {
	return post(`/insurance/api/product/productLabel`);
}
//获取产品详情
export function getProductdetailsPost(productId) {
	return postUrl(`/insurance/api/product/details`, productId);
}
// 获取保险商信息
export function getInsuerInfo(productId, data) {
	return postUrl('/insurance/api/insure/insureInfo', productId, data);
}
// 分页查询商品
export function getselectInfoPost(data) {
	return post('/insurance/api/cpsLoad/selectInfo', data);
}
// H5首页面定制根据id查询详情
export function getselectByIdH5(id) {
	return get(`/insurance/api/cpsIndex/selectById/${id}`);
}
// 品牌查询code
export function cpsLoginCode(code) {
	return get(`/insurance/api/cpsLoad/cpsLogin/${code}`);
}
// 查询登录状态
export function http_getLoginStatus() {
	return post('/insurance/api/user/checkLogin');
}
// 加载保险公司列表
export function getfindAllInsInsurer() {
	return get(`/insurance/api/common/findAllInsInsurer`);
}
// 保单查询
export function selectPolicy(data) {
	return post(`/insurance/api/cpsLoad/selectPolicy`, data);
}
// 获取产品费率信息
export function http_getProductPrice(productId) {
	return get(`/insurance/api/niumo/getPriceListByProId/${productId}`);
}

// 承载页面标签信息查询
export function http_selectLabelName(cpsLoadQuery) {
	return post(`/insurance/api/cpsLoad/selectLabelName`, cpsLoadQuery);
}
